'use client';

import { Button, Modal } from '@common/components';
import { usePathname } from 'next/navigation';

import { ROUTES } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useGlobalStore } from '@/stores/GlobalStore';

export const LoginRedirectionConfirmModal = () => {
  const router = useCustomRouter();
  const pathname = usePathname();
  const [
    isShowLoginRedirectionConfirmModal,
    setIsShowLoginRedirectionConfirmModal,
  ] = useGlobalStore((state) => [
    state.isShowLoginRedirectionConfirmModal,
    state.setIsShowLoginRedirectionConfirmModal,
  ]);

  const handleClickConfirm = () => {
    setIsShowLoginRedirectionConfirmModal(false);
    router.push(
      ROUTES.LOGIN.withSearchParams({
        searchParams: { redirect: pathname ?? '' },
      }),
    );
  };
  const handleClose = () => {
    setIsShowLoginRedirectionConfirmModal(false);
  };

  return (
    <Modal
      className="flex flex-col items-center"
      opened={isShowLoginRedirectionConfirmModal}
      onClose={handleClose}
    >
      <p className="typo-body1 font-700 text-Gray-900 mb-8">알림</p>
      <p className="typo-body3 font-400 text-Gray-700 mb-24">
        웰로에 회원가입을 하시고
        <br />
        정책 지원금을 확인해 보세요.
      </p>

      <Button
        className="px-[16.5px] py-12 typo-body3 font-700 cursor-pointer"
        theme="primary-fill"
        onClick={handleClickConfirm}
      >
        웰로 시작하기
      </Button>
    </Modal>
  );
};
