'use client';

import { useEffect } from 'react';

import { usePathname } from 'next/navigation';

import { MEMBER_JOIN_STATUS } from '@/api';
import { ROUTES } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useAuthStore } from '@/stores/AuthStore';

export const JoinController = () => {
  const isNewUser = useAuthStore(({ myInfo }) => {
    const memberJoinStatus = myInfo?.memberJoinStatus;

    return memberJoinStatus
      ? [MEMBER_JOIN_STATUS.NO_PHONE, MEMBER_JOIN_STATUS.NO_NICKNAME].includes(
          memberJoinStatus,
        )
      : false;
  });

  const pathname = usePathname();
  const router = useCustomRouter();

  const isAllowedPath = pathname
    ? ['/join', '/login'].some((path) => pathname.startsWith(path))
    : true;

  useEffect(() => {
    if (isAllowedPath || !isNewUser) return;

    router.replace(
      ROUTES.JOIN_COMMON.withSearchParams({ searchParams: { step: 1 } }),
    );
  }, [isNewUser, isAllowedPath, router]);

  return <></>;
};
