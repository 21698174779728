'use client';

import { Button, Modal } from '@common/components';
import { useShallow } from 'zustand/react/shallow';

import { ENV, ROUTES, SEARCH_PARAMS } from '@/constants';
import { useAccessToken } from '@/hooks/useAccessToken';
import { KB_PAY_CUSTOM_SCHEME } from '@/modules/kb-pay';
import { useGlobalStore } from '@/stores/GlobalStore';

export const WelloWebLinkModal = () => {
  const { accessToken, refreshToken } = useAccessToken();
  const [welloWebLinkModalProps, setWelloWebLinkModalProps] = useGlobalStore(
    useShallow((state) => [
      state.welloWebLinkModalProps,
      state.setWelloWebLinkModalProps,
    ]),
  );

  const { descriptions, link } = welloWebLinkModalProps || {};

  const closeModal = () => setWelloWebLinkModalProps(null);

  return (
    <Modal opened={!!welloWebLinkModalProps} onClose={closeModal}>
      <h2 className="typo-body1 font-700 text-Gray-black">
        웰로 웹페이지에서
        <br />
        이용해 주세요
      </h2>
      <p className="typo-body3 text-Gray-700 pb-16 pt-8">{descriptions}</p>
      <footer className="flex gap-8">
        <Button
          className="flex-1"
          dimensions="H48"
          theme="primary-line"
          onClick={closeModal}
        >
          뒤로가기
        </Button>
        <Button
          className="flex-1"
          dimensions="H48"
          onClick={() => {
            const welloDomain = ENV.NEXT_PUBLIC_DOMAIN;

            if (!link) return;

            const linkUrl = new URL(link, location.origin);

            if (!accessToken || !refreshToken) return;

            const redirectSearchParams: {
              [key: string]: string;
            } = {};

            linkUrl.searchParams.forEach((value, key) => {
              redirectSearchParams[key] = value;
            });

            window.location.href = `${KB_PAY_CUSTOM_SCHEME.EXTERNAL_PREFIX}${welloDomain}${ROUTES.CROSS_DOMAIN_CALLBACK.withSearchParams(
              {
                searchParams: {
                  accessToken,
                  refreshToken,
                  [SEARCH_PARAMS.REDIRECT]: linkUrl.pathname,
                  [SEARCH_PARAMS.REDIRECT_SEARCH_PARAMS]:
                    JSON.stringify(redirectSearchParams),
                },
              },
            )}`;

            closeModal();
          }}
        >
          이동
        </Button>
      </footer>
    </Modal>
  );
};
