'use client';

import { useEffect } from 'react';
import { Link, Plus } from 'react-feather';

import { BottomDrawer } from '@common/components';
import { useToast } from '@common/hooks';
import { CustomError, cn, isDev } from '@common/utils';
import Image from 'next/image';
import { usePathname, useSearchParams } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { ENV, SEARCH_PARAMS } from '@/constants';
import { useCheckWelloApp } from '@/hooks/useCheckWelloApp';
import { useClipboard } from '@/hooks/useClipboard';
import { MESSAGE_KEYWORD, useHandleApp } from '@/modules/wello-app';
import { useAuthStore } from '@/stores/AuthStore';
import { useDeviceInfoStore, WEBVIEW_TYPE } from '@/stores/DeviceStore';
import { useGlobalStore } from '@/stores/GlobalStore';

interface KakaoWindow extends Window {
  Kakao?: {
    isInitialized?: () => boolean;
    init?: (clientId: string) => void;
    Share?: {
      sendScrap?: (options: { requestUrl: string }) => void;
    };
  };
}

export const ShareDrawer = () => {
  const [webviewType, osType] = useDeviceInfoStore(
    useShallow((state) => [state.webviewType, state.osType]),
  );

  const { isWelloApp } = useCheckWelloApp();

  const [shareLink, setShareLink] = useGlobalStore(
    useShallow((state) => [state.shareLink, state.setShareLink]),
  );

  const memberCode = useAuthStore((state) => state.myInfo?.memberCode);

  const shareLinkUrl = (() => {
    if (!shareLink) return;

    const pathname = new URL(shareLink).pathname;

    let mainDomain = ENV.NEXT_PUBLIC_DOMAIN;

    if (!isDev) {
      mainDomain = mainDomain.replace('https://', 'https://www.');
    }

    const url = new URL(pathname, mainDomain);

    url.searchParams.set(SEARCH_PARAMS.ENTER_TYPE, 'share');

    if (memberCode) {
      url.searchParams.set(SEARCH_PARAMS.SHARE_BY, memberCode);
    }

    return url.href;
  })();

  const copy = useClipboard();

  const {
    request: checkKakaoInstallRequest,
    response: checkKakaoInstallResponse,
  } = useHandleApp({
    type: MESSAGE_KEYWORD.CHECK_KAKAO_INSTALLED,
  });

  const {
    request: requestShare,
    isPending,
    response,
  } = useHandleApp({
    type: MESSAGE_KEYWORD.SHARE_CONTENT,
  });

  const isKakaoShareResponse: string | undefined = response?.params?.share_type;

  useEffect(() => {
    if (isKakaoShareResponse) {
      setShareLink(null);
    }
  }, [isKakaoShareResponse, setShareLink]);

  useEffect(() => {
    const kakao = (window as KakaoWindow).Kakao;

    if (!kakao) return;

    if (typeof kakao.isInitialized !== 'function') return;

    if (kakao.isInitialized()) return;

    const kakaoClientId = process.env.NEXT_PUBLIC_KAKAO_CLIENT_ID;

    if (!kakaoClientId)
      throw new CustomError({
        return_message: 'Kakao Client ID가 없습니다.',
      });

    kakao.init?.(kakaoClientId);
  }, []);

  const { toast } = useToast();

  const isMobileWeb =
    !webviewType && osType && ['ios', 'android'].includes(osType);

  const isOpened = !!shareLink;

  useEffect(() => {
    if (isOpened) {
      checkKakaoInstallRequest();
    }
  }, [checkKakaoInstallRequest, isOpened]);

  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    setShareLink(null);
  }, [pathname, searchParams, setShareLink]);

  return (
    <BottomDrawer opened={!!shareLink} onClose={() => setShareLink(null)}>
      <BottomDrawer.HandleWrapper>
        <BottomDrawer.Handle />
      </BottomDrawer.HandleWrapper>
      <BottomDrawer.Contents className="pb-16">
        <div
          className={cn('flex gap-6 justify-around mx-42', {
            'justify-between': isWelloApp,
          })}
        >
          {webviewType !== WEBVIEW_TYPE.KB_PAY ? (
            <button
              className="flex flex-col gap-6 items-center justify-center border-none cursor-pointer"
              onClick={() => {
                if (isPending) return;

                if (isWelloApp) {
                  if (checkKakaoInstallResponse?.isSuccess) {
                    requestShare({
                      share_type: 'kakao',
                      url: shareLinkUrl,
                    });
                  } else {
                    toast({
                      message: '카카오톡이 설치되어 있지 않아요',
                      type: 'fail',
                    });
                  }
                } else {
                  if (!shareLinkUrl) return;

                  const kakao = (window as KakaoWindow).Kakao;

                  kakao?.Share?.sendScrap?.({
                    requestUrl: shareLinkUrl,
                  });
                }
              }}
            >
              <div className="flex justify-center items-center w-48 h-48 rounded-pill bg-[#fbdf03]">
                <Image
                  alt="kakao"
                  height={24}
                  src="/assets/pngs/login/kakao.png"
                  width={24}
                />
              </div>
              <h4 className="text-Gray-900 font-400 typo-body4">카카오톡</h4>
            </button>
          ) : null}
          <button
            className="flex flex-col gap-6 items-center justify-center border-none cursor-pointer"
            onClick={() => {
              try {
                if (shareLinkUrl) {
                  return copy({
                    value: shareLinkUrl,
                  });
                }
              } finally {
                setShareLink(null);
              }
            }}
          >
            <div className="flex justify-center items-center w-48 h-48 rounded-pill bg-Gray-500 text-Gray-white">
              <Link />
            </div>
            <h4 className="text-Gray-900 font-400 typo-body4">URL 복사</h4>
          </button>
          {isWelloApp || isMobileWeb ? (
            <button
              className="flex flex-col gap-6 items-center justify-center border-none cursor-pointer"
              onClick={() => {
                try {
                  if (!shareLinkUrl) return;

                  if (isWelloApp)
                    return requestShare({
                      share_type: 'uni',
                      url: shareLinkUrl,
                    });

                  if (isMobileWeb)
                    return navigator.share({
                      url: shareLinkUrl,
                    });
                } finally {
                  setShareLink(null);
                }
              }}
            >
              <div className="flex justify-center items-center w-48 h-48 rounded-pill bg-Gray-200 text-Gray-black">
                <Plus />
              </div>
              <h4 className="text-Gray-900 font-400 typo-body4">더 보기</h4>
            </button>
          ) : null}
        </div>
      </BottomDrawer.Contents>
    </BottomDrawer>
  );
};
