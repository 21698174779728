'use client';

import { useEffect, useState } from 'react';

import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { useShallow } from 'zustand/react/shallow';

import { GA_ID, GTM_ID } from '@/constants';
import { useAuthStore } from '@/stores/AuthStore';
import {
  SUB_DOMAIN,
  WEBVIEW_TYPE,
  useDeviceInfoStore,
} from '@/stores/DeviceStore';

import { KbAppController, KbHistoryStack } from './kb-pay';
import { AppController, AppModal } from './wello-app';

const GA_SCRIPT = {
  __html: `
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", "${GA_ID}");
`,
};

const GTM_SCRIPT = {
  __html: `
(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, "script", "dataLayer", "${GTM_ID}");
`,
};

const SEO_JSON_SCRIPT = {
  __html: JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'WELLO INC.',
    url: 'https://www.welfarehello.com',
    sameAs: [
      'https://blog.naver.com/wello_official',
      'https://www.instagram.com/wello_welfarehello/',
      'https://www.youtube.com/@wello_welfarehello',
    ],
  }),
};

const CROSS_TARGET_SCRIPT = {
  __html: `
    !function (w,d,s,u,t,ss,fs) {
        if(w.ex2cts)return;t=w.ex2cts={};if(!window.t) window.t = t;
        t.push = function() {t.callFunc?t.callFunc.apply(t,arguments) : t.cmd.push(arguments);};
        t.cmd=[];ss = document.createElement(s);ss.async=!0;ss.src=u;
        fs=d.getElementsByTagName(s)[0];fs.parentNode.insertBefore(ss,fs);
    }(window,document,'script','//st2.exelbid.com/js/cts2.js');
    ex2cts.push('init', '674567a74628526b1f52dc57', '.welfarehello.com');
    ex2cts.push('track', 'pageview');
  `,
};

const KAKA_PIXEL_SDK_ID = '6586438192824206503';

const ClientScript = () => {
  const webviewType = useDeviceInfoStore(({ webviewType }) => webviewType);
  const [isLogin, memberCode] = useAuthStore(
    useShallow((state) => [state.isLogin, state.myInfo?.memberCode]),
  );

  const isMounted = isLogin !== undefined;

  const gaDataLayerScript = {
    __html: `
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ memberCode: '${memberCode}' });
    window.dataLayer.push({ userEnv: '${
      {
        [WEBVIEW_TYPE.KB_PAY]: 'KB_PAY_APP',
        [WEBVIEW_TYPE.WELLO_APP]: 'WELLO_APP',
        web: 'WELLO_WEB',
      }[webviewType ?? 'web']
    }' });
    `,
  };

  const [isKakaoPixelLoaded, setIsKakaoPixelLoaded] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    if (isKakaoPixelLoaded) {
      try {
        window.kakaoPixel.getPageView();
      } catch (e) {
        /* empty */
      }
    }
  }, [isKakaoPixelLoaded, pathname]);

  return isMounted ? (
    <>
      <Script dangerouslySetInnerHTML={gaDataLayerScript} id="DATA_LAYER" />
      <Script dangerouslySetInnerHTML={GA_SCRIPT} id="GA" />
      <Script
        dangerouslySetInnerHTML={GTM_SCRIPT}
        id="GTM"
        strategy="afterInteractive"
      />
      <Script
        src="//t1.daumcdn.net/kas/static/kp.js"
        strategy="afterInteractive"
        type="text/javascript"
        onLoad={() => {
          window.kakaoPixel = kakaoPixel(KAKA_PIXEL_SDK_ID);
          setIsKakaoPixelLoaded(true);
        }}
      />
    </>
  ) : null;
};

const GA_SRC = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;

export const GlobalScript = () => (
  <>
    <Script async src={GA_SRC} />
    <Script
      dangerouslySetInnerHTML={SEO_JSON_SCRIPT}
      id="SEO-JSON"
      strategy="afterInteractive"
      type="application/ld+json"
    />
    <Script
      dangerouslySetInnerHTML={CROSS_TARGET_SCRIPT}
      id="CROSS_TARGET"
      strategy="afterInteractive"
      type="text/javascript"
    />
    <ClientScript />
  </>
);

export const GlobalController = () => {
  const subDomain = useDeviceInfoStore(({ subDomain }) => subDomain);

  switch (subDomain) {
    case SUB_DOMAIN.KB_PAY:
      return (
        <>
          <KbHistoryStack />
          <KbAppController />
        </>
      );

    case SUB_DOMAIN.WELLO_APP:
      return (
        <>
          <AppController />
          <AppModal />
        </>
      );

    default:
      return <></>;
  }
};
