'use client';

import { useEffect } from 'react';

import { createUuid } from '@common/utils';
import { useMutation } from '@tanstack/react-query';
import { usePathname, useSearchParams } from 'next/navigation';

import { AUTH_API, UNAUTH_API } from '@/api';
import { useAuthStore } from '@/stores/AuthStore';
import { useDeviceInfoStore } from '@/stores/DeviceStore';
import { LOCAL_STORAGE_KEY } from '@/stores/GlobalStore';

export const WelloTracker = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const isLogin = useAuthStore((state) => state.isLogin);
  const [osType, viewportType] = useDeviceInfoStore((state) => [
    state.osType,
    state.viewportType,
  ]);

  const { mutate: postWelloTrack } = useMutation({
    mutationFn: () => {
      const getOrCreateUuid = () => {
        try {
          const storedUuid = localStorage.getItem(LOCAL_STORAGE_KEY.WELLO_UUID);
          if (storedUuid) {
            return storedUuid;
          }
          const newUuid = createUuid();
          localStorage.setItem(LOCAL_STORAGE_KEY.WELLO_UUID, newUuid);

          return newUuid;
        } catch (error) {
          console.error('로컬 스토리지 접근 오류:', error);

          return createUuid();
        }
      };

      const welloUuid = getOrCreateUuid();

      const previousUrl = (() => {
        if (typeof window === 'undefined') return '';

        if (window.history.state && window.history.state.url) {
          return window.history.state.url;
        }

        return document.referrer || '';
      })();

      const fullUrl = `${pathname}${
        searchParams?.toString() ? `?${searchParams.toString()}` : ''
      }`;

      const requestBody = {
        event_name: 'pageView',
        dictionary: {
          url: fullUrl,
          osType,
          viewportType,
          previousUrl,
          welloUuid,
        },
      };

      return isLogin
        ? AUTH_API.postWelloTrack({ requestBody })
        : UNAUTH_API.postWelloTrack({ requestBody });
    },
  });

  useEffect(() => {
    if (isLogin !== undefined) {
      postWelloTrack();
    }
  }, [pathname, postWelloTrack, isLogin]);

  return <></>;
};
