'use client';

import { BottomDrawer, Button } from '@common/components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

import { AUTH_API, TERM_CATEGORY } from '@/api';
import { termsQueryOptionsKeys } from '@/query-factory/terms';
import { useGlobalStore } from '@/stores/GlobalStore';

export const LocationServiceConsentDrawer = () => {
  const [
    handleLocationServiceConsentDrawer,
    setHandleLocationServiceConsentDrawer,
    setTermsDrawerType,
  ] = useGlobalStore(
    useShallow((state) => [
      state.handleLocationServiceConsentDrawer,
      state.setHandleLocationServiceConsentDrawer,
      state.setTermsDrawerType,
    ]),
  );

  const isLocationServiceConsentDrawerOpened =
    !!handleLocationServiceConsentDrawer;

  const queryClient = useQueryClient();

  const updateLocationServiceConsentMutation = useMutation({
    mutationFn: (isAgree: boolean) =>
      AUTH_API.updateLocationServiceConsent({
        requestBody: {
          location_service_consent_yn: isAgree,
        },
      }),
    onSuccess: async (_, isAgree) => {
      await queryClient.invalidateQueries({
        queryKey: [termsQueryOptionsKeys.locationServiceConsent],
      });

      await handleLocationServiceConsentDrawer?.(isAgree);

      setHandleLocationServiceConsentDrawer(undefined);
    },
  });

  return (
    <BottomDrawer opened={isLocationServiceConsentDrawerOpened}>
      <BottomDrawer.HandleWrapper>
        <BottomDrawer.Handle />
      </BottomDrawer.HandleWrapper>
      <BottomDrawer.Contents>
        <h3 className="mb-8 typo-body2 text-700 text-Gray-900">
          위치기반서비스 이용약관에 동의해주세요
        </h3>
        <p className="typo-body4 font-400 text-Gray-700">
          현재 위치 정보 확인을 위해 위치기반서비스 이용약관 동의가 필요합니다.
          <button
            className="text-Gray-black font-600 underline ml-8"
            type="button"
            onClick={() =>
              setTermsDrawerType(TERM_CATEGORY.LOCATION_POLICY_SELECT)
            }
          >
            보기
          </button>
        </p>

        <footer className="mt-28 flex gap-8">
          <Button
            className="min-w-120"
            loading={updateLocationServiceConsentMutation.isPending}
            theme="sub-line"
            onClick={() => {
              updateLocationServiceConsentMutation.mutate(false);
            }}
          >
            동의안함
          </Button>
          <Button
            className="flex-1"
            loading={updateLocationServiceConsentMutation.isPending}
            onClick={() => {
              updateLocationServiceConsentMutation?.mutate(true);
            }}
          >
            동의함
          </Button>
        </footer>
      </BottomDrawer.Contents>
    </BottomDrawer>
  );
};
