import type { ToastOptions } from '@common/hooks';

import { useRef } from 'react';

import { getSubDomain } from '@common/utils';
import { useShallow } from 'zustand/react/shallow';

import { AUTH_API, AuthOpenApi } from '@/api';
import { STORAGE_KEY } from '@/constants';
import { useAuthStore } from '@/stores/AuthStore';
import { SUB_DOMAIN } from '@/stores/DeviceStore';

import { useAccessToken } from './useAccessToken';

interface LogoutOptions {
  redirect?: string;
  forInit?: boolean;
  toastOptions?: ToastOptions;
}

export const useLogout = () => {
  const { setAccessToken, setRefreshToken } = useAccessToken();
  const [setMyInfo, setIsLogin, setIsDisabledAccessControl] = useAuthStore(
    useShallow((state) => [
      state.setMyInfo,
      state.setIsLogin,
      state.setIsDisabledAccessControl,
    ]),
  );

  const { current } = useRef({
    logout: async (options?: LogoutOptions) => {
      const { redirect, forInit, toastOptions } = options ?? {};

      if (!forInit) {
        setIsDisabledAccessControl(true);

        if (AuthOpenApi.TOKEN) {
          await AUTH_API.logout();
        }
      }

      sessionStorage.removeItem(STORAGE_KEY.POLICY_FILTER_LANDING);

      if (toastOptions) {
        sessionStorage.setItem(
          STORAGE_KEY.LOGOUT_TOAST,
          JSON.stringify(toastOptions),
        );
      }

      setAccessToken(null);
      setRefreshToken(null);
      setMyInfo(null);
      setIsLogin(false);

      if (forInit || getSubDomain() === SUB_DOMAIN.KB_PAY) return;

      if (redirect) {
        return location.replace(redirect);
      }

      return location.reload();
    },
  });

  return current;
};
