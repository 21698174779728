'use client';

import { Transition } from 'react-transition-group';

import { cn } from '@common/utils';
import { usePathname } from 'next/navigation';

import BadgeCheckFillSelected from '@/assets/svgs/navigation/BadgeCheck-Fill-Selected.svg';
import BadgeCheckFill from '@/assets/svgs/navigation/BadgeCheck-Fill.svg';
import HomeFillSelected from '@/assets/svgs/navigation/Home-Fill-Selected.svg';
import HomeFill from '@/assets/svgs/navigation/Home-Fill.svg';
import MoreFillSelected from '@/assets/svgs/navigation/More-Fill-Selected.svg';
import MoreFill from '@/assets/svgs/navigation/More-Fill.svg';
import VoucherFillSelected from '@/assets/svgs/navigation/Voucher-Fill-Selected.svg';
import VoucherFill from '@/assets/svgs/navigation/Voucher-Fill.svg';
import CommunityFillSelected from '@/assets/svgs/navigation/_Community-Fill-Selected.svg';
import CommunityFill from '@/assets/svgs/navigation/_Community-Fill.svg';
import { CustomLink } from '@/components/atom/CustomLink';
import { MODULE_ROUTES, ROUTES, SELECTOR } from '@/constants';
import { MESSAGE_KEYWORD, useHandleApp } from '@/modules/wello-app';
import { useAuthStore } from '@/stores/AuthStore';
import { SUB_DOMAIN, useDeviceInfoStore } from '@/stores/DeviceStore';
import { useGlobalStore } from '@/stores/GlobalStore';
import { findRoute } from '@/utils';

export const GlobalNavigation = () => {
  const [setIsGlobalLoading, isHomeBottomDrawerOpened] = useGlobalStore(
    (state) => [state.setIsGlobalLoading, state.isHomeBottomDrawerOpened],
  );

  const pathname = usePathname();

  const currentRoute = findRoute(pathname);

  const hasBottomNav = currentRoute?.hasBottomNav ?? true;

  const Container = hasBottomNav ? 'nav' : 'div';

  const { request: requestHaptic } = useHandleApp({
    type: MESSAGE_KEYWORD.HAPTIC,
  });

  const isKbPay = useDeviceInfoStore(
    (state) => state.subDomain === SUB_DOMAIN.KB_PAY,
  );

  const isLoading = useAuthStore((state) => state.isLogin === undefined);

  return (
    <Container
      className={cn(
        'relative flex justify-center h-fit bg-Gray-white w-full transition-colors duration-300',
        {
          'bg-Gray-black': isHomeBottomDrawerOpened,
        },
      )}
      id={SELECTOR.GLOBAL_NAVIGATION}
    >
      {!isLoading && currentRoute?.hasBottomNav ? (
        <Transition in={!isHomeBottomDrawerOpened} timeout={300}>
          {(state) => {
            return (
              <ul
                className={cn(
                  'transition-all duration-300 flex justify-between',
                  'items-center w-full h-70 px-22 border-t-1 border-Gray-50 shadow-large',
                  {
                    'animate-fade-up border-none': state === 'entering',
                    'animate-fade-up animate-reverse h-0 border-none':
                      state === 'exiting',
                    'h-0 hidden': state === 'exited',
                  },
                )}
              >
                {[
                  {
                    name: '홈',
                    route: ROUTES.HOME,
                    href: ROUTES.HOME.pathname,
                    icon: {
                      on: HomeFillSelected,
                      off: HomeFill,
                    },
                    isHighlight: false,
                  },
                  {
                    name: '맞춤정책',
                    route: ROUTES.RECOMMEND_POLICY_LIST,
                    href: ROUTES.RECOMMEND_POLICY_LIST.pathname,
                    icon: {
                      on: BadgeCheckFillSelected,
                      off: BadgeCheckFill,
                    },

                    isHighlight: false,
                  },
                  {
                    name: '커뮤니티',
                    route: ROUTES.COMMUNITY_CATEGORY,
                    href: ROUTES.COMMUNITY.pathname,
                    icon: {
                      on: CommunityFillSelected,
                      off: CommunityFill,
                    },
                    isHighlight: false,
                  },
                  {
                    name: '바우처',
                    route: ROUTES.VOUCHER_CATEGORY,
                    href: ROUTES.VOUCHER_CATEGORY.pathname({
                      categoryId: 0,
                    }),
                    icon: {
                      on: VoucherFillSelected,
                      off: VoucherFill,
                    },
                    isHighlight: false,
                  },
                  {
                    name: '더보기',
                    route: ROUTES.PROFILE,
                    href: ROUTES.PROFILE.pathname,
                    icon: {
                      on: MoreFillSelected,
                      off: MoreFill,
                    },
                    isHighlight: true,
                  },
                ].map(({ name, href, icon, route, isHighlight }) => {
                  let isActived =
                    href === '/'
                      ? pathname === href ||
                        pathname === MODULE_ROUTES.KB_HOME.pathname
                      : route.id === currentRoute.id;

                  isActived =
                    pathname?.split('/')[1] === href?.split('/')[1]
                      ? true
                      : isActived;

                  const Icon = isActived ? icon.on : icon.off;

                  if (isKbPay && route.id === ROUTES.VOUCHER_CATEGORY.id)
                    return null;

                  return (
                    <li key={name} className="w-53">
                      <CustomLink
                        aria-label={`${name} 페이지로 이동`}
                        className={cn(
                          'flex-center flex-col gap-5 text-Gray-600 typo-caption1 relative',
                          {
                            'text-Gray-800 font-700 cursor-default': isActived,
                          },
                        )}
                        href={href}
                        onClick={(e) => {
                          if (isActived) return e.preventDefault();

                          setIsGlobalLoading(true);
                          requestHaptic();
                        }}
                      >
                        <Icon
                          className={cn('size-24', {
                            'animate-jump animate-duration-300': isActived,
                          })}
                        />
                        {name}
                        {isHighlight ? (
                          <div className="absolute size-6 top-0 right-[8.5px] bg-R-500 rounded-pill" />
                        ) : null}
                      </CustomLink>
                    </li>
                  );
                })}
              </ul>
            );
          }}
        </Transition>
      ) : null}
    </Container>
  );
};
