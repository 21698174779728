'use client';

import { useState } from 'react';

import { BottomDrawer } from '@common/components';
import { cn } from '@common/utils';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { Selectbox } from '@/components/client';
import { termsQueryOptions } from '@/query-factory/terms';
import { useGlobalStore } from '@/stores/GlobalStore';

import styles from './TermsDrawer.module.scss';

export const TermsDrawer = () => {
  const [termsDrawerType, setTermsDrawerType] = useGlobalStore((state) => [
    state.termsDrawerType,
    state.setTermsDrawerType,
  ]);

  const { data } = useQuery(termsQueryOptions.termsHistory(termsDrawerType));

  const [order, setOrder] = useState(0);
  const terms = data?.context?.contents;
  const selectedTerms = terms?.[order];
  const newLineFilterContents = selectedTerms?.contents?.replace(/\n/g, '');

  return (
    <BottomDrawer
      opened={!!termsDrawerType}
      onClose={() => setTermsDrawerType(null)}
    >
      <BottomDrawer.HandleWrapper>
        <BottomDrawer.Handle />
      </BottomDrawer.HandleWrapper>
      <BottomDrawer.Contents>
        <header className="py-12 bg-Gray-white sticky top-0">
          <h2 className="typo-body2 font-700">{selectedTerms?.title}</h2>
        </header>
        <article
          className={cn(
            styles.contents,
            'typo-body3 font-400 text-Gray-800 overflow-auto',
          )}
          dangerouslySetInnerHTML={{
            __html: newLineFilterContents || '',
          }}
        />
        <footer className="sticky bottom-0 py-16 w-full bg-Gray-white">
          <Selectbox
            float="top"
            options={terms?.map(({ title, create_at }, index) => ({
              label: `${title} ${dayjs(create_at).format('YYYY.MM.DD')}`,
              value: index,
            }))}
            value={order}
            onChange={(e) => setOrder(e.value)}
          />
        </footer>
      </BottomDrawer.Contents>
    </BottomDrawer>
  );
};
