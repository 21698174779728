'use client';

import React, { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { NavermapsProvider } from 'react-naver-maps';

import {
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { LoadingPageTemplate } from '@/components/client';
import { ENV } from '@/constants';
import { GlobalController } from '@/modules';
import { useMountDeviceInfoStore } from '@/stores/DeviceStore';

import { AuthProvider } from './_components/AuthProvider';
import { GlobalLoading } from './_components/GlobalLoading';
import { HistoryObserver } from './_components/HistoryObserver';
import { JoinController } from './_components/JoinController';
import { useGlobalEventListener } from './_hooks/useGlobalEventListener';

interface GlobalProviderProps {
  children: React.ReactNode;
}

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onSuccess: async (_data, _variables, _context, mutation) => {
      if (mutation.meta) {
        const { reset, invalidates } = mutation.meta;

        if (reset) {
          queryClient.resetQueries({
            predicate: (query) => {
              const hasIncludeQueryKey = reset.some((queryKeys) => {
                const hasAllQueryKey = queryKeys.every((queryKey) =>
                  query.queryKey.includes(queryKey),
                );

                return hasAllQueryKey;
              });

              return hasIncludeQueryKey;
            },
          });
        }
        if (invalidates) {
          await queryClient.invalidateQueries({
            predicate: (query) => {
              const hasIncludeQueryKey = invalidates.some((queryKeys) => {
                const hasAllQueryKey = queryKeys.every((queryKey) =>
                  query.queryKey.includes(queryKey),
                );

                return hasAllQueryKey;
              });

              return hasIncludeQueryKey;
            },
          });
        }
      }
    },
  }),
  defaultOptions: {
    queries: {
      staleTime: 300_000,
    },
  },
});

export const GlobalProvider = ({ children }: GlobalProviderProps) => {
  useMountDeviceInfoStore();
  useGlobalEventListener();

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider
        defaultSetOptions={{
          path: '/',
        }}
      >
        <HistoryObserver />
        <Suspense fallback={<LoadingPageTemplate />}>
          <AuthProvider>
            <GlobalController />
            <JoinController />
            <NavermapsProvider
              ncpClientId={ENV.NEXT_PUBLIC_NAVER_CLOUD_CLIENT_ID}
            >
              <GlobalLoading />
              {children}
            </NavermapsProvider>
          </AuthProvider>
        </Suspense>
        {process.env.NODE_ENV === 'development' ? (
          <ReactQueryDevtools
            buttonPosition="bottom-left"
            initialIsOpen={false}
          />
        ) : null}
      </CookiesProvider>
    </QueryClientProvider>
  );
};
