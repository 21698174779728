'use client';

import { useEffect } from 'react';

import { useToast } from '@common/hooks';
import { CustomError, ERROR_CODE, type ServerResponse } from '@common/utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import { usePathname } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { UNAUTH_API, AuthOpenApi } from '@/api';
import { ROUTES, STORAGE_KEY } from '@/constants';
import { useAccessToken } from '@/hooks/useAccessToken';
import { useLogout } from '@/hooks/useLogout';
import { authQueryOptions } from '@/query-factory';
import { useAuthStore } from '@/stores/AuthStore';
import { useGlobalStore } from '@/stores/GlobalStore';

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [setIsLogin, setMyInfo, isLogin] = useAuthStore(
    useShallow((state) => [state.setIsLogin, state.setMyInfo, state.isLogin]),
  );

  const setIsGlobalLoading = useGlobalStore(
    (state) => state.setIsGlobalLoading,
  );

  const { accessToken, refreshToken, setAccessToken, setRefreshToken } =
    useAccessToken();

  AuthOpenApi.TOKEN = accessToken || undefined;

  const { logout } = useLogout();

  const refreshTokenMutation = useMutation({
    mutationFn: async (refreshToken: string) => {
      const { context } = await UNAUTH_API.getRefreshToken({ refreshToken });
      const { access_token, refresh_token } = context ?? {};
      if (!access_token || !refresh_token) {
        throw new CustomError({
          return_message: '서버에서 정상적인 토큰을 발급하지 못했습니다.',
          return_code: ERROR_CODE.UNKNOWN_UNAUTHORIZED,
        });
      }

      return {
        access_token,
        refresh_token,
      };
    },
    onSuccess: ({ access_token, refresh_token }) => {
      setAccessToken(access_token);
      setRefreshToken(refresh_token);
    },
    onError: () => {
      console.info('logout by refreshTokenMutation');

      logout();
    },
  });

  const myInfoQuery = useQuery({
    ...authQueryOptions.myInfo({
      accessToken,
    }),
    enabled: !!accessToken && !refreshTokenMutation.isPending,
  });

  const pathname = usePathname();

  useEffect(() => {
    if (!pathname) return;

    if (
      accessToken ||
      [
        ROUTES.CROSS_DOMAIN_AUTH.pathname,
        ROUTES.CROSS_DOMAIN_CALLBACK.pathname,
      ].includes(pathname)
    )
      return;

    if (refreshToken) return refreshTokenMutation.mutate(refreshToken);

    console.info('init by refreshToken not exist');

    logout({ forInit: true });
  }, [accessToken, logout, refreshToken, refreshTokenMutation, pathname]);

  const { toastError, toast } = useToast();

  useEffect(() => {
    const logoutToast = sessionStorage.getItem(STORAGE_KEY.LOGOUT_TOAST);

    if (!logoutToast) return;

    const toastOptions = JSON.parse(logoutToast);

    toast(toastOptions);

    sessionStorage.removeItem(STORAGE_KEY.LOGOUT_TOAST);
  }, [toast]);

  useEffect(() => {
    if (myInfoQuery.isError) {
      logout({
        redirect: ROUTES.HOME.pathname,
        toastOptions: {
          type: 'fail',
          message: (myInfoQuery.error as ServerResponse).return_message ?? '',
        },
      });

      return;
    }

    if (!myInfoQuery.isFetched || myInfoQuery.isLoading) return;

    if (!myInfoQuery.data) {
      logout({
        toastOptions: {
          type: 'fail',
          message: '내 정보를 불러오는데 실패했습니다.',
        },
      });

      return;
    }

    setMyInfo(myInfoQuery.data);
    setIsLogin(true);
  }, [
    logout,
    myInfoQuery.data,
    myInfoQuery.error,
    myInfoQuery.isError,
    myInfoQuery.isFetched,
    myInfoQuery.isLoading,
    setIsLogin,
    setMyInfo,
    toastError,
  ]);

  useEffect(() => {
    setIsGlobalLoading(isLogin === undefined);
  }, [isLogin, setIsGlobalLoading]);

  const accessTokenStatusQuery = useQuery({
    ...authQueryOptions.checkAccessTokenStatus(accessToken),
    enabled: !!accessToken && !refreshTokenMutation.isPending,
  });

  useEffect(() => {
    if (accessTokenStatusQuery.isFetched && !accessTokenStatusQuery.data) {
      setAccessToken(null);
    }
  }, [
    accessTokenStatusQuery.data,
    accessTokenStatusQuery.isFetched,
    setAccessToken,
  ]);

  useEffect(() => {
    if (accessTokenStatusQuery.isError) {
      console.info('logout by accessTokenStatusQuery error');

      logout();
    }
  }, [accessTokenStatusQuery.isError, logout]);

  return <>{children}</>;
};
