import { Suspense, useEffect } from 'react';

import { usePathname, useSearchParams } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { STORAGE_KEY } from '@/constants';
import { useHistoryStore } from '@/stores/HistoryStore/useHistoryStore';

const setHistoryExpire = () => {
  sessionStorage.setItem(
    STORAGE_KEY.HISTORY_EXPIRE,
    (Date.now() + 5_000).toString(),
  );
};

const HistoryObserverController = () => {
  const searchParams = useSearchParams()?.toString();
  const pathname = usePathname();

  let url = pathname || '';

  if (searchParams) url += `?${searchParams}`;

  const [setCustomHistory, setHistoryIndex] = useHistoryStore(
    useShallow((state) => [state.setCustomHistory, state.setHistoryIndex]),
  );

  useEffect(() => {
    const { historyIndex, customHistory } = useHistoryStore.getState();

    const historyState: {
      historyIndex?: number;
    } = history.state;

    if (typeof historyState.historyIndex === 'number') {
      customHistory.set(historyState.historyIndex, url);
    } else {
      const hasReplaceMark =
        sessionStorage.getItem(STORAGE_KEY.REPLACE_MARK) === 'true';

      historyState.historyIndex = historyIndex;

      if (hasReplaceMark) {
        sessionStorage.removeItem(STORAGE_KEY.REPLACE_MARK);
        customHistory.set(historyIndex, url);
      } else {
        historyState.historyIndex += 1;
        customHistory.set(historyState.historyIndex, url);
      }

      history.replaceState(historyState, '');
    }

    setHistoryIndex(historyState.historyIndex);
    setCustomHistory(customHistory);

    window.addEventListener('beforeunload', setHistoryExpire);

    return () => {
      window.removeEventListener('beforeunload', setHistoryExpire);
    };
  }, [url, setCustomHistory, setHistoryIndex]);

  return null;
};

export const HistoryObserver = () => {
  return (
    <Suspense>
      <HistoryObserverController />
    </Suspense>
  );
};
