import { useEffect, useLayoutEffect } from 'react';

import { debounce } from 'es-toolkit';

import { SELECTOR } from '@/constants';

export const useGlobalEventListener = () => {
  useEffect(() => {
    const mainWrapper = document.getElementById(SELECTOR.MAIN);

    if (!mainWrapper) return;

    const handleScroll = debounce(({ target }: Event) => {
      if (target instanceof Element) {
        history.replaceState(
          {
            ...history.state,
            scrollY: target.scrollTop,
          },
          '',
        );
      }
    }, 100);

    mainWrapper.addEventListener('scroll', handleScroll);

    return () => {
      mainWrapper.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    const updateHeight = () => {
      const height = window.visualViewport?.height || window.innerHeight;

      document.documentElement.style.setProperty(
        '--viewport-height',
        `${height}px`,
      );
    };

    updateHeight();

    window.addEventListener('resize', updateHeight);
    window.addEventListener('scroll', updateHeight);
    window.visualViewport?.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
      window.removeEventListener('scroll', updateHeight);
      window.visualViewport?.removeEventListener('resize', updateHeight);
    };
  }, []);

  useEffect(() => {
    const lockHtmlScroll = () => {
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    };

    window.addEventListener('scroll', lockHtmlScroll);

    return () => {
      window.removeEventListener('scroll', lockHtmlScroll);
    };
  }, []);
};
