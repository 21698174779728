'use client';

import { BottomDrawer, Button } from '@common/components';
import { useToast } from '@common/hooks';
import { SvgTarget2 } from '@common/icon';
import { CustomError } from '@common/utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import { usePathname } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { AUTH_API, MEMBER_JOIN_STATUS } from '@/api';
import { ROUTES } from '@/constants';
import { useLocation } from '@/hooks/useLocation';
import { authQueryOptionsKeys } from '@/query-factory';
import { termsQueryOptions } from '@/query-factory/terms';
import { useAuthStore } from '@/stores/AuthStore';

export const RegionDrawer = () => {
  const [isLogin, myInfo] = useAuthStore(
    useShallow((state) => [state.isLogin, state.myInfo]),
  );

  const locationServiceConsentQuery = useQuery(
    termsQueryOptions.locationServiceConsent(isLogin),
  );

  const pathname = usePathname();

  const needRegionData =
    myInfo?.memberJoinStatus === MEMBER_JOIN_STATUS.EMPTY_FILTER &&
    !(myInfo.codeRegion && myInfo.codeSubRegion);

  const location = useLocation();

  const { toastError } = useToast();

  const postRegionDataMutation = useMutation({
    mutationFn: AUTH_API.postRegionData,
    meta: {
      invalidates: [[authQueryOptionsKeys.myInfo]],
    },
    onError: (error) =>
      toastError({
        error,
      }),
  });

  if (!pathname || !locationServiceConsentQuery.isSuccess) return null;

  const isEnabledPathname =
    pathname === ROUTES.HOME.pathname ||
    [
      ROUTES.PROFILE.pathname,
      ROUTES.COMMUNITY.pathname,
      ROUTES.RECOMMEND_POLICY_LIST.pathname,
    ].some((path) => pathname.startsWith(path));

  return isEnabledPathname ? (
    <BottomDrawer opened={needRegionData}>
      <BottomDrawer.HandleWrapper>
        <BottomDrawer.Handle />
      </BottomDrawer.HandleWrapper>
      <BottomDrawer.Contents className="pb-16">
        <h3 className="mb-8 typo-body2 text-700 text-Gray-900">
          지역을 선택해 주세요
        </h3>
        <p className="text-Gray-700 typo-body4 font-400 mb-16">
          선택된 지역의 정책과 게시글을 볼 수 있어요.
        </p>
        <p className="typo-caption1 font-400 text-Gray-600">
          지역 설정은{' '}
          <span className="text-Gray-black">{`맞춤정책 > 맞춤정보 수정 > 지역`}</span>
          에서 언제든지 수정할 수 있어요.
        </p>
        <Button
          className="mt-28 w-full"
          loading={location.isLoading || postRegionDataMutation.isPending}
          onClick={async () => {
            const { data } = await location.refetch();

            const { codeRegion, codeSubRegion } = data ?? {};

            if (!codeRegion || !codeSubRegion)
              throw new CustomError({
                return_message: '위치 정보를 가져오는데 실패했습니다.',
              });

            postRegionDataMutation.mutate({
              requestBody: {
                code_region: codeRegion,
                code_sub_region: codeSubRegion,
              },
            });
          }}
        >
          <SvgTarget2 className="size-16 mr-6" name="현재 위치" />
          현재 위치로 선택하기
        </Button>
      </BottomDrawer.Contents>
    </BottomDrawer>
  ) : null;
};
