'use client';

import type { ReactNode } from 'react';

import { ToastProvider as CommonToastProvider } from '@common/components';
import { cn } from '@common/utils';
import { usePathname } from 'next/navigation';

import { findRoute } from '@/utils';

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const pathanme = usePathname();

  const currentRoute = findRoute(pathanme);

  const isTopToast = currentRoute?.toastDirection === 'top';

  const result = [
    children,
    <CommonToastProvider
      key="toast-provider"
      className={cn(
        'relative max-w-[calc(100%-32px)] w-full mobile-sm:mx-auto p-0 z-50',
        isTopToast ? 'top-16' : 'bottom-0 mobile-sm:bottom-16 max-h-0',
      )}
      position={isTopToast ? 'top-center' : 'bottom-center'}
    />,
  ];

  if (isTopToast) result.reverse();

  return result;
};
