'use client';

import { SvgChevronright } from '@common/icon';
import { cn } from '@common/utils';
import Image from 'next/image';

import Logo from '@/assets/svgs/logo.svg';
import { CustomLink } from '@/components/atom/CustomLink';
import { API_ROUTES, COMMUNITY_HEADER_ITEMS, ENV, ROUTES } from '@/constants';
import { useAuthStore } from '@/stores/AuthStore';
import { useGlobalStore } from '@/stores/GlobalStore';

import styles from './LayoutAsideSection.module.scss';

const LayoutAsideSection = () => {
  const [isLogin] = useAuthStore((state) => [state.isLogin]);
  const [setIsShowLoginRedirectionConfirmModal] = useGlobalStore((state) => [
    state.setIsShowLoginRedirectionConfirmModal,
  ]);

  const QR_CODE_SIZE_X = 110;
  const QR_CODE_SIZE_Y = 110;
  const builtInApiUrl = `${ENV.NEXT_PUBLIC_DOMAIN}${API_ROUTES.DOWNLOAD_APP.pathname}`;
  const createQRCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=${QR_CODE_SIZE_X}x${QR_CODE_SIZE_Y}&data=${builtInApiUrl}`;
  const bannerImageUrl = `/assets/pngs/aside-section-banner-${isLogin ? 'private' : 'guest'}.png`;

  return (
    <aside className="sticky top-0 flex flex-1 flex-col justify-center h-cdvh pl-50 mobile:hidden">
      <section className={styles.wrapper}>
        <section className="flex flex-col items-start w-fit">
          <Logo className="w-[8.75rem] h-[2.895625rem] mb-[2.2187rem]" />
          <h2 className="typo-h4 font-600 text-Gray-900 mb-[1.8237rem]">
            내게 맞는 정책
            <br className={styles['new-line']} /> 웰로에서 찾아요!
          </h2>
          <CustomLink
            className={cn(
              'relative w-[30rem] h-[10.625rem] mb-[3.3637rem] p-0 active:filter-none',
              styles.banner,
            )}
            href={ROUTES.COMMUNITY_CATEGORY.pathname({
              category: COMMUNITY_HEADER_ITEMS.hometownNews.pathname,
            })}
            onClick={(e) => {
              if (isLogin) return;

              e.preventDefault();
              setIsShowLoginRedirectionConfirmModal(true);
            }}
          >
            <Image fill alt="정책" loading="lazy" src={bannerImageUrl} />
          </CustomLink>
        </section>
        <section className={styles['link-section']}>
          <section className={styles['link-wrapper']}>
            <CustomLink
              href="https://www.wello.info/"
              rel="noreferrer noopener"
              target="_blank"
            >
              <div className={styles['link-contents']}>
                서비스가
                <br className={styles['new-line']} /> 더 궁금한가요?
                <SvgChevronright className={styles['link-icon']} />
              </div>
            </CustomLink>
            <CustomLink
              href="https://s3.ap-northeast-2.amazonaws.com/www.wello.info/public_download/%E1%84%8B%E1%85%B0%E1%86%AF%E1%84%85%E1%85%A9+%E1%84%89%E1%85%A5%E1%84%87%E1%85%B5%E1%84%89%E1%85%B3+%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5.pdf"
              rel="noreferrer noopener"
              target="_blank"
            >
              <div className={styles['link-contents']}>
                5,200만 국민에게
                <br className={styles['new-line']} /> 정책을 알리고 싶다면?
                <SvgChevronright className={styles['link-icon']} />
              </div>
            </CustomLink>
          </section>
          <Image
            alt="QR코드"
            height={110}
            loading="lazy"
            src={createQRCodeUrl}
            width={112}
            onError={(e) => {
              e.currentTarget.src = IMAGE_PLACEHOLDER;
            }}
          />
        </section>
      </section>
    </aside>
  );
};

export default LayoutAsideSection;

const IMAGE_PLACEHOLDER = '/assets/pngs/placeholder.png';
